import HBusService from 'Service/HBusService';

export const newAddressLinkClickEvent = () => {
  HBusService.sendEventRequest('LinkClick', {
    location: '',
    page_type: 'static',
    page_value: 'eskiyi-getir-yeniyi-gotur',
    placement: 'eskiyi-getir-yeniyi-gotur-drawer',
    position: '',
    text: 'Yeni adres ekle',
    url: ''
  });
};

export const selectAddressLinkClickEvent = ({ text, position }) => {
  HBusService.sendEventRequest('LinkClick', {
    location: '',
    page_type: 'static',
    page_value: 'eskiyi-getir-yeniyi-gotur',
    placement: 'eskiyi-getir-yeniyi-gotur-drawer',
    position,
    text,
    url: ''
  });
};
