const LocalStorageService = {
  getItem: key => {
    const value = localStorage.getItem(key);
    return JSON.parse(value);
  },
  setItem: (key, value) => {
    return new Promise(resolve => {
      localStorage.setItem(key, JSON.stringify(value));
      resolve();
    });
  },
  removeItem: key => {
    localStorage.removeItem(key);
  }
};

export default LocalStorageService;
