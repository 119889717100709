import jsCookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { environment } from '../appConfig';
import { redirectWithPrefix } from './helper';

const ApplicationKeys = {
  MobileIphone: 'BF448871-DB72-4CD7-A0E0-1F82BF410370',
  MobileAndroid: '8093F525-1BAC-49C0-8FB7-C9F7B2DA04CF',
  WebSite: 'AF7F2A37-CC4B-4F1C-87FD-FF3642F67ECB',
  MobileSite: 'D5BABFEB-AEE3-420B-9857-0697C8716A7E',
  MobileIpad: '8B497575-C938-4807-8060-130380F67F7C',
  BackOffice: 'DACEEFC8-68BC-40FB-B693-BC7198F42474',
  LegacyMobileApp: 'A0A16B0C-9FCE-48BB-8881-4AC263A938F3',
  KanalD: '71C6ED59-AA28-4852-8C7B-3FE28480E7EC'
};

const setBrowserCookie = (...args) => {
  return jsCookie.set(...args);
};
const getBrowserCookie = (...args) => {
  return jsCookie.get(...args);
};

const getUserJWT = () => {
  return getBrowserCookie('jwt');
};

const checkUserJWT = (prefix = '') => {
  const jwtCookie = getUserJWT();
  if (!jwtCookie) {
    redirectWithPrefix(prefix);
    return false;
  }
  return true;
};

const applicationType = key => {
  if (
    key === ApplicationKeys.MobileIphone ||
    key === ApplicationKeys.MobileIpad ||
    key === ApplicationKeys.MobileAndroid ||
    key === ApplicationKeys.LegacyMobileApp
  ) {
    return 'Mobile';
  }
  return 'Site';
};

const getCustomer = () => {
  const jwtCookie = getUserJWT();

  if (!jwtCookie) {
    return false;
  }

  let customer = null;

  const jwtInfo = jwtDecode(jwtCookie);

  if (jwtInfo) {
    customer = {
      ...jwtInfo,
      Application: applicationType(jwtInfo.AppKey)
    };
  }

  return customer;
};

const isValidUser = () => {
  const customer = getCustomer();
  return customer && customer.IsAuthenticated === 'True' && customer.Email !== undefined;
};

const redirectWithPrefixIfUserNotValid = (prefix = '') => {
  if (!isValidUser()) {
    redirectWithPrefix(prefix);
    return false;
  }
  return true;
};

const redirectWithPrefixIfJWTisUndefined = (prefix = '') => {
  if (!getUserJWT()) {
    redirectWithPrefix(prefix);
    return false;
  }
  return true;
};

const setIsFavoritesRequiredToReload = (value = true) => {
  setBrowserCookie('isFavoritesRequiredToReload', value, {
    expires: 1,
    domain: '.hepsiburada.com'
  });
};

const getSiteType = () => {
  const customer = getCustomer();
  switch (customer.AppKey) {
    case ApplicationKeys.MobileAndroid:
      return 'android_native';
    case ApplicationKeys.MobileIphone:
    case ApplicationKeys.MobileIpad:
      return 'ios_native';
    case ApplicationKeys.MobileSite:
      return 'mobile';
    default:
      return 'desktop';
  }
};

const isInfluencer = () => {
  const customer = getCustomer();
  const influencerTag = {
    prod: 5043,
    test: 72,
    local: 72
  };
  return customer?.p?.t?.includes(influencerTag[environment]);
};

export {
  setBrowserCookie,
  getBrowserCookie,
  checkUserJWT,
  getUserJWT,
  applicationType,
  getCustomer,
  isValidUser,
  redirectWithPrefixIfUserNotValid,
  redirectWithPrefixIfJWTisUndefined,
  setIsFavoritesRequiredToReload,
  getSiteType,
  isInfluencer
};
