import HBusService from 'Service/HBusService';

export const notFoundLinkClickEvent = location => {
  HBusService.sendEventRequest('LinkClick', {
    location,
    position: '',
    page_type: 'mylist',
    page_value: '',
    placement: '',
    text: 'listelerime_git',
    url: ''
  });
};

export default {
  notFoundLinkClickEvent
};
