const ROUTE_PATHS = {
  NOT_FOUND_PAGE: '/NotFound',
  MY_LISTS: '/MyLists',
  ACCOUNT_MENU: '/AccountMenu',
  SERVER_ERROR_PAGE: '/ServerError',
  MY_LIST_DETAIL: '/MyListDetail',
  BOOKMARK_HANDLER: '/BookmarkHandler',
  FAVORITES_HANDLER: '/FavoritesHandler',
  KVKK_APPROVE: '/KVKKApprove',
  SIGN_UP: '/SignUp',
  OTP_MODAL: '/OtpModal',
  UPDATE_ADDRESS: '/UpdateAddress',
  SELECT_ADDRESS: '/SelectAddress',
  NOTIFICATION_PERMISSION: '/NotificationPermission',
  VERIFY_TCKN: '/VerifyTCKN',
  VAS: '/VAS',
  ADD_TO_MY_LISTS: '/AddToMyLists',
  TRADE_IN: '/TradeIn',
  TBA: '/TBA',
  PRODUCT_LIST: '/ProductList'
};

export default ROUTE_PATHS;
