import HBusService from 'Service/HBusService';

export const tradeinDrawerEvent = locationInfo => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: locationInfo,
    step_number: '0'
  });
};

export const selectNewLocationEvent = () => {
  HBusService.sendEventRequest('LinkClick', {
    page_type: 'pdp',
    placement: 'tradein',
    text: '',
    location: 'city selection'
  });
};

export const selectExistAddressEvent = () => {
  HBusService.sendEventRequest('LinkClick', {
    page_type: 'pdp',
    placement: 'tradein',
    text: '',
    location: 'address selection'
  });
};

export const clickMoveOnButtonEvent = () => {
  HBusService.sendEventRequest('LinkClick', {
    page_type: 'pdp',
    placement: 'tradein',
    text: 'devam et',
    location: 'LocationPopup'
  });
};

export const clickCancelButtonEvent = () => {
  HBusService.sendEventRequest('LinkClick', {
    page_type: 'pdp',
    placement: 'tradein',
    text: 'vazgeç',
    location: 'LocationPopup'
  });
};

export const newLocationDrawerEvent = () => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: 'NoAddress',
    step_number: '0'
  });
};

export const clickMoveOnWithNewAddresButtonEvent = () => {
  HBusService.sendEventRequest('LinkClick', {
    page_type: 'pdp',
    placement: 'tradein',
    text: 'ilerle',
    location: 'new address'
  });
};

export const redirectBuybackDrawerEvent = () => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: 'Buyback'
  });
};

export default {
  tradeinDrawerEvent,
  selectNewLocationEvent,
  selectExistAddressEvent,
  clickMoveOnButtonEvent,
  clickCancelButtonEvent,
  newLocationDrawerEvent,
  clickMoveOnWithNewAddresButtonEvent,
  redirectBuybackDrawerEvent
};
