import HBusService from 'Service/HBusService';
import { getSiteType } from 'Utils/helper';

export const updateAddressActionEvent = data => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'update',
    page_type: 'Cart',
    page_value: 'DeliveryView',
    location: 'AddressVerificationDrawer',
    placement: 'AddressUpdateButton',
    site_type: 'desktop',
    data
  });
};

export const deleteAddressActionEvent = data => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'delete',
    page_type: 'Cart',
    page_value: 'DeliveryView',
    location: 'AddressVerificationDrawer',
    placement: 'AddressDeleteButton',
    site_type: 'desktop',
    data
  });
};

export const deleteAddressSubmitActionEvent = data => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'delete',
    page_type: 'Cart',
    page_value: 'DeliveryView',
    location: 'AddressDeleteModal',
    placement: 'AddressDeleteButton',
    site_type: 'desktop',
    data
  });
};

export const cancelAddressDeleteActionEvent = data => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'delete',
    page_type: 'Cart',
    page_value: 'DeliveryView',
    location: 'AddressDeleteModal',
    placement: 'CancelButton',
    site_type: 'desktop',
    data
  });
};

export const updateAddressViewEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'view',
    page_type: params?.page_type,
    page_value: params?.page_value,
    location: 'Adreslerim',
    service: 'HB',
    site_type: getSiteType()
  });
};

export const closeDrawerActionEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'click',
    page_type: 'AddressDrawer',
    page_value: params?.page_value,
    location: 'Adreslerim',
    placement: 'button',
    text: 'Geri Dön',
    site_type: getSiteType(),
    success: true
  });
};

export const addressDrawerCancelClickEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'click',
    page_type: 'AddressDrawer',
    page_value: params?.page_value,
    location: 'Adreslerim',
    placement: 'button',
    text: 'Vazgeç',
    site_type: getSiteType(),
    success: true
  });
};

export const addressDrawerSubmitClickEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'click',
    page_type: 'AddressDrawer',
    page_value: params?.page_value,
    site_type: getSiteType(),
    location: 'Adreslerim',
    placement: 'button',
    text: params?.text,
    service: 'HB',
    success: true,
    data: params?.data
  });
};

export const addressAutoSuggestionClickEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'click',
    page_type: params?.page_type,
    page_value: params?.page_value,
    placement: 'AddressSearch',
    service: 'HB',
    site_type: getSiteType(),
    success: true,
    data: params?.data
  });
};

export const addressAutoSuggestionServiceEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'view',
    page_type: params?.page_type,
    page_value: params?.page_value,
    placement: 'AddressSuggestion',
    service: 'HB',
    site_type: getSiteType(),
    success: !params?.exception_message,
    exception_message: params?.exception_message
  });
};

export const addressAutoSuggestionInputChangeEvent = params => {
  HBusService.sendEventRequest('AddressVerification', {
    event_type: 'click',
    page_type: params?.page_type,
    page_value: params?.page_value,
    placement: params?.placement,
    placement_title: params?.placement_title,
    service: 'HB',
    site_type: getSiteType(),
    success: true
  });
};
