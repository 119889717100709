import { MenuNameEnums } from 'Model/Enums';

import HBusService from 'Service/HBusService';

// eslint-disable-next-line import/prefer-default-export
export const accountMenuClickEvent = menu => {
  HBusService.sendEventRequest('SidebarClick', {
    location: 'sidebar',
    click_menu: MenuNameEnums[(menu?.clickMenu)],
    click_submenu: menu.clickSubMenu ? MenuNameEnums[(menu?.clickSubMenu)] : '',
    page: menu?.pageType
  });
};
