import HBusService from 'Service/HBusService';

export const lazyRegisterViewEvent = params => {
  HBusService.sendEventRequest('LazyRegisterView', {
    ...params,
    isLazy: 3,
    site_type: 'desktop'
  });
};

export const lazyRegisterEvent = params => {
  HBusService.sendEventRequest('LazyRegister', {
    ...params,
    site_type: 'desktop'
  });
};

export default {
  lazyRegisterViewEvent,
  lazyRegisterEvent
};
