const voltran = require('/home/node/app/node_modules/voltranjs/src/index');

import React, { PureComponent } from 'react';

/** Route */
import ROUTE_PATHS from 'Routes/routeConstants';

/** Service */
import CustomListService from 'Service/customList/CustomListService';
import LocalStorageService from 'Service/LocalStorageService';

/** Utils */
import {
  getBrowserCookie,
  setIsFavoritesRequiredToReload,
  redirectWithPrefixIfUserNotValid,
  isValidUser,
  getCustomer
} from 'Utils/cookieUtils';
import { showToast } from 'Utils/helper';

/** Model */
import { CustomListTypeEnums, CustomListNameEnums } from 'Model/Enums';

/** Event */
import { deleteItemFromListEvent, addItemToListEvent } from 'Event/index';

export class FavoritesHandler extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    };
  }

  componentDidMount() {
    this.setWindowCac();
    if (isValidUser()) {
      this.loadData();
    } else {
      LocalStorageService.removeItem('favoritesData');
      this.dispatchLoadedEvent();
    }
  }

  loadData = () => {
    const isFavoritesRequiredToReload = getBrowserCookie('isFavoritesRequiredToReload');
    const favoritesData = this.getLocalStorageFavoritesData();
    const customer = getCustomer();

    if (
      isFavoritesRequiredToReload === 'true' ||
      !favoritesData ||
      (favoritesData && favoritesData.expireAt < new Date()) ||
      (favoritesData && customer && favoritesData.ownerId !== customer.UserId)
    ) {
      this.fetchFavorites().then(this.checkLikeHash);
    } else {
      this.dispatchLoadedEvent();
    }
  };

  checkLikeHash = () => {
    if (window.location.hash) {
      const product = JSON.parse(decodeURIComponent(window.location.hash.split('#')[1]));
      window.location.hash = '';
      if (!this.isExist(product.sku)) {
        this.onHeartClick(product);
      }
    }
  };

  setWindowCac = () => {
    window.CAC = {
      ...window.CAC,
      FAVORITESHANDLER: {
        isExist: this.isExist,
        onHeartClick: this.onHeartClick,
        isLoaded: this.isLoaded
      }
    };
  };

  fetchFavorites = () => {
    return new Promise(resolve => {
      CustomListService.getFavoritesItemSku(false).then(response => {
        const expireAt = new Date().setMinutes(new Date().getMinutes() + 10);
        const customer = getCustomer();

        let favoritesData = {
          items: [],
          expireAt,
          ownerId: customer.UserId
        };

        if (response.status === 200) {
          favoritesData = {
            ...response.data
          };
        }
        setIsFavoritesRequiredToReload(false);
        this.setLocalStorageFavoritesData(favoritesData).then(this.dispatchLoadedEvent);
        resolve();
      });
    });
  };

  dispatchLoadedEvent = () => {
    const event = new CustomEvent('CAC.FavoritesHandlerLoaded', { bubbles: true });
    window.dispatchEvent(event);
    this.setState({
      isLoaded: true
    });
  };

  handleErrors = messages => {
    messages?.map(message => {
      switch (message?.code) {
        case 1201: {
          showToast({
            type: 'error',
            title: message?.message,
            message: message?.subMessage
          });
          break;
        }
        default: {
          showToast({
            title: 'Bir hata oluştu, lütfen tekrar deneyiniz.',
            type: 'error'
          });
          break;
        }
      }
    });
  };

  getFavoritesData = () => {
    let favoritesData = this.getLocalStorageFavoritesData();
    if (!favoritesData) {
      const customer = getCustomer();

      favoritesData = {
        items: [],
        expireAt: new Date().setMinutes(new Date().getMinutes() + 10),
        ownerId: customer.UserId
      };
    }
    return favoritesData;
  };

  getLocalStorageFavoritesData = () => {
    return LocalStorageService.getItem('favoritesData');
  };

  setLocalStorageFavoritesData = data => {
    const favoritesData = {
      ...data
    };
    const customer = getCustomer();
    if (customer) {
      favoritesData.ownerId = customer.UserId;
    }
    return LocalStorageService.setItem('favoritesData', favoritesData);
  };

  isExist = sku => {
    const favoritesData = this.getFavoritesData();

    if (favoritesData && favoritesData.items && favoritesData.items.length > 0) {
      return favoritesData.items.find(x => x === sku);
    }
    return false;
  };

  // eslint-disable-next-line consistent-return
  onHeartClick = async product => {
    if (!redirectWithPrefixIfUserNotValid(`#${JSON.stringify(product)}`)) {
      return Promise.reject();
    }

    const requestObject = {
      sku: product.sku,
      listingId: product.listingId || 'Hepsiburada',
      id: product.productId,
      position: product.position,
      brand: product.brand,
      categoryId: product.categoryId,
      price: product.price,
      productStatus: product.productStatus
    };

    return new Promise(resolve => {
      if (this.isExist(requestObject.sku)) {
        this.deleteItemFromList(requestObject).then(resolve);
      } else {
        this.addItemToList(requestObject).then(resolve);
      }
    });
  };

  addItemToList = requestObject => {
    return new Promise(resolve => {
      CustomListService.addItemToList(
        CustomListTypeEnums.FAVORITES,
        CustomListTypeEnums.FAVORITES,
        [requestObject]
      ).then(res => {
        if (res?.status !== 202) {
          this.handleErrors(res?.data?.messages);
          this.addItemToListEvent(
            { ...requestObject, productId: requestObject.id },
            false,
            res?.data?.messages?.[0]?.message
          );
          resolve();
          return;
        }
        const favoritesData = this.getFavoritesData();
        favoritesData.items.push(requestObject.sku);
        this.setLocalStorageFavoritesData(favoritesData);
        this.addItemToListEvent({ ...requestObject, productId: requestObject.id });
        resolve();
      });
    });
  };

  deleteItemFromList = requestObject => {
    return new Promise(resolve => {
      CustomListService.deleteItemFromList(
        CustomListTypeEnums.FAVORITES,
        CustomListTypeEnums.FAVORITES,
        [requestObject]
      ).then(() => {
        const favoritesData = this.getFavoritesData();
        favoritesData.items = favoritesData.items.filter(x => x !== requestObject.sku);
        this.setLocalStorageFavoritesData(favoritesData);
        this.deleteItemFromListEvent({ ...requestObject, productId: requestObject.id });
        resolve();
      });
    });
  };

  isLoaded = () => {
    const { isLoaded } = this.state;
    return isLoaded;
  };

  addItemToListEvent = (product, success = true, errorMessage = null) => {
    const pageValue = this.getEventPageValue();

    const eventObject = {
      ...product,
      listName: CustomListNameEnums.FAVORITES,
      location: 'heart',
      pageType: 'listing',
      pageValue,
      success,
      errorMessage
    };

    addItemToListEvent(eventObject);
  };

  deleteItemFromListEvent = product => {
    const pageValue = this.getEventPageValue();

    const eventObject = {
      ...product,
      listName: CustomListNameEnums.FAVORITES,
      location: 'heart',
      pageType: 'listing',
      pageValue
    };

    deleteItemFromListEvent(eventObject);
  };

  getEventPageValue = () => {
    if (process.env.BROWSER) {
      const urlParams = new URLSearchParams(window.location.search);
      const search = urlParams.get('q');

      if (search) {
        return search;
      }
      return window.location.pathname.split('-').splice(-1)[0];
    }
    return null;
  };

  render() {
    return <div />;
  }
}

const component = voltran.default.withBaseComponent(
  FavoritesHandler,
  ROUTE_PATHS.FAVORITES_HANDLER
);

component.services = [];

export default component;
