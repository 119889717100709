import HBusService from 'Service/HBusService';

export const otpModalUpdateSuccessEvent = () => {
  HBusService.sendEventRequest('AccountUpdate', {
    page_type: 'MyAccount',
    site_type: 'desktop',
    success: true,
    page_value: 'AccountInfo',
    placement: 'GSMUpdateModal'
  });
};

export const otpModalUpdateFailureEvent = (message, code) => {
  HBusService.sendEventRequest('AccountUpdate', {
    page_type: 'MyAccount',
    site_type: 'desktop',
    success: false,
    page_value: 'AccountInfo',
    placement: 'GSMUpdateModal',
    errorcode: code,
    exception_message: message
  });
};

export const otpModalViewEvent = () => {
  HBusService.sendEventRequest('OtpModal', {
    page: 'AccountInfo',
    site_type: 'desktop',
    event_type: 'view',
    reason: 'null'
  });
};

export const otpModalCloseEvent = () => {
  HBusService.sendEventRequest('OtpModal', {
    page: 'AccountInfo',
    site_type: 'desktop',
    event_type: 'closed',
    reason: 'closed'
  });
};

export const otpModalTimeoutEvent = () => {
  HBusService.sendEventRequest('OtpModal', {
    page: 'AccountInfo',
    site_type: 'desktop',
    event_type: 'closed',
    reason: 'timeout'
  });
};
