const buildVersion = process.env.APP_BUILD_VERSION || '1.0.0';

module.exports = {
  port: process.env.VOLTRAN_PORT || 4000,
  baseUrl: 'https://cac-ui.hepsiburada.com',
  bifrostUrl: 'https://listelerim-gw.hepsiburada.com',
  checkOutUrl: 'https://checkout.hepsiburada.com',
  loginUrl: 'https://www.hepsiburada.com/uyelik/giris?ReturnUrl=',
  sfBaseUrl: 'https://www.hepsiburada.com',
  myListUrl: 'https://listelerim.hepsiburada.com',
  environment: 'prod',
  mediaUrl: `https://images.hepsiburada.net/cac/content/www/cac-ui/${buildVersion}`,
  corsWhiteListDomains: [/^(.*\.)?hepsiburada\.com?(.*)/],
  logLevel: 'warn',
  isCssClassNameObfuscationEnabled: true,
  voltranCommonUrl: 'https://voltrancommon.hepsiburada.com/common.js',
  previewHBusVersion: '3.3.38',
  lookUpApiUrl: 'https://cac-lookup-api.hepsiburada.com/api',
  loyaltyApiUrl: 'https://loyalty-content.hepsiburada.com/api',
  voltranGatewayApiUrl: 'https://customer-voltran-gw.hepsiburada.com/api',
  checkoutVoltranUrl: 'https://checkout-ui.hepsiburada.com',
  loyaltyVoltranUrl: 'https://loyalty-voltran.hepsiburada.com',
  services: {},
  newrelicEnabled: true,
  hbusEnabled: false,
  tradeInGatewayApiUrl: 'https://tradein-gw.hepsiburada.com',
  sfVoltranUrl: 'https://voltran.hepsiburada.com',
  tradeInStaticPageUrl: 'https://www.hepsiburada.com/staticpage/12783330836288'
};
