import {
  convertPriceToInt,
  getListName,
  generateProductStatus,
  prepareProductLabelsForEvent
} from 'Utils/helper';
import { CustomListTypeEnums } from 'Model/Enums';
import HBusService from 'Service/HBusService';

export const createListEvent = item => {
  const product = {
    listingId: item.listingId,
    name: item.name || '',
    price: convertPriceToInt(item.price) || '',
    productStatus: item.productStatus || '',
    sku: item.sku
  };

  HBusService.sendEventRequest('CreateMyListClick', {
    list_name: item.listName,
    list_id: item?.listId,
    list_type: `${CustomListTypeEnums.CUSTOM_LIST}`,
    location: 'productdetail',
    page_type: 'productdetail',
    page_value: item.sku,
    product,
    product_status: item.productStatus,
    success: item.success
  });
};

export const addItemToListEvent = item => {
  const products = [
    {
      brand: item.brand || '',
      categoryId: Number(item.categoryId) || '',
      listingId: item.listingId,
      position: item.position || 1,
      price: convertPriceToInt(item.price) || '',
      productId: item.productId,
      sku: item.sku,
      productStatus: item.productStatus || ''
    }
  ];

  HBusService.sendEventRequest('AddToMyList', {
    list_name: item.listName,
    list_type: item.listType,
    list_id: item?.listId?.toString(),
    location: item.location,
    page_type: item.pageType,
    page_value: item.pageValue,
    success: item?.success,
    error_message: item?.errorMessage,
    products
  });
};

export const componentViewEvent = item => {
  HBusService.sendEventRequest('ComponentView', {
    page_type: item?.pageType,
    page_value: item?.pageValue,
    component: item?.component,
    component_detail: item?.componentDetail,
    component_order: '',
    position: item?.position || '',
    merchant_id: item?.merchantId || '',
    merchant_name: item?.merchantName || '',
    listing_id: item.listingId,
    price: convertPriceToInt(item.price) || '',
    product_id: item.productId,
    category_hierarchy: item?.categoryHierarchy || '',
    placement: '',
    url: '',
    component_id: ''
  });
};

export const PDPAddItemToListEvent = item => {
  const products = [
    {
      brand: item?.brand || '',
      categoryId: Number(item?.categoryId) || '',
      listingId: item?.listingId,
      position: item?.position || 1,
      price: convertPriceToInt(item?.price) || '',
      product_id: item?.productId,
      sku: item?.sku,
      product_status: item?.productStatus || '',
      shipping_type: item?.shippingType,
      tag_label: ''
    }
  ];

  HBusService.sendEventRequest('AddToMyList', {
    list_name: item?.listName,
    list_type: item?.listType,
    location: item?.location,
    page_type: item?.pageType,
    page_value: item?.pageValue,
    success: item?.success,
    error_message: item?.errorMessage,
    products
  });
};

export const PDPLinkClickEvent = ({ location, text, position, placement, pageValue }) => {
  HBusService.sendEventRequest('LinkClick', {
    location,
    position,
    page_value: pageValue,
    placement,
    text,
    page_type: 'product detail',
    url: ''
  });
};

export const bottomsheetViewEvent = ({ text }) => {
  HBusService.sendEventRequest('BottomsheetView', {
    text,
    totalAddresses: 0,
    type: 'mylist bottomsheet',
    page_type: 'product detail',
    hasAddress: false
  });
};

export const addRemoveMyListEvent = item => {
  const products = [
    {
      page_value: item?.pageValue,
      brand: item.brand || '',
      category_id: Number(item.categoryId) || '',
      listing_id: item.listingId,
      position: item.position || 1,
      price: convertPriceToInt(item.price) || '',
      product_id: item.productId,
      sku: item.sku,
      product_status: item.productStatus || ''
    }
  ];

  HBusService.sendEventRequest('AddRemoveMyList', {
    text: item?.text,
    type: 'mylist bottomsheet',
    page_type: 'pdp',
    list_name: item?.listName,
    products
  });
};

export const PDPCreateListEvent = item => {
  HBusService.sendEventRequest('CreateMyListClick', {
    list_name: item.listName,
    list_type: 'CustomList',
    location: 'mylist',
    page_type: 'product detail',
    page_value: item.sku,
    alarm_status: item.alarmStatus,
    success: item.success,
    list_status: item?.listStatus
  });
};

export const searchBoxCMTEvent = ({ keyword }) => {
  HBusService.sendEventRequest('SearchBoxCMT', {
    keyword,
    page_value: 'mylist',
    page_type: 'product detail'
  });
};

export const bottomsheetScrollingEvent = item => {
  HBusService.sendEventRequest('BottomsheetScrolling', {
    product_status: item?.productStatus,
    category_name: item?.categoryName,
    category_id: item?.categoryId,
    sku: item?.sku,
    text: 'Listelerim',
    page_type: 'product detail',
    totalList: item?.totalCount.toString(),
    location: 'mylist'
  });
};

export const addItemToSflEvent = item => {
  HBusService.sendEventRequest('AddToMyList', {
    list_name: item.listName,
    list_id: item?.listId,
    location: item.location,
    page_value: item.pageValue,
    page_type: item.pageType,
    products: item.products,
    success: item?.success,
    error_message: item?.errorMessage
  });
};

export const deleteItemFromListEvent = item => {
  const products = [
    {
      brand: item.brand || '',
      categoryId: Number(item.categoryId) || '',
      listingId: item.listingId,
      position: item.position || 1,
      price: convertPriceToInt(item.price) || '',
      productId: item.productId,
      sku: item.sku
    }
  ];

  HBusService.sendEventRequest('DeleteFromMylist', {
    action: 'removelist',
    list_name: item.listName,
    list_id: item?.listId,
    location: item.location,
    page_value: item.pageValue,
    page_type: item.pageType,
    products
  });
};

export const addToMyListClickEvent = eventObject => {
  HBusService.sendEventRequest('LinkClick', eventObject);
};

export const addToCartEvent = (
  selectedList,
  allProducts,
  items,
  location,
  showOnlyFavorites = false
) => {
  if (items && items.length > 0) {
    const pageValue = getListName(selectedList.listType);
    const listId = selectedList?.id;

    const productsArr = [];

    items.forEach(item => {
      const product = {};
      product.campaignId = '';
      product.id = '';
      const productIndex = allProducts.indexOf(allProducts.find(e1 => e1.sku === item.sku)) + 1;
      product.position = productIndex;
      product.listingId = item.listingId;
      product.mainproduct = false;
      product.product_status = item.isPreOrder ? 'PreOrder' : 'Instock';
      product.product_id = item.productId;
      product.shippingType = '';
      product.price = convertPriceToInt(item.finalPriceOnSale);
      product.sku = item.sku;
      product.is_price_discount = item?.priceAddedToList > 0;
      productsArr.push(product);
    });

    HBusService.sendEventRequest('AddToCartClick', {
      canonical_url: window.location.href,
      page_type: 'mylist',
      page_value: pageValue,
      category: {},
      category_hierarchy: [],
      category_status: '',
      experiments: [],
      location,
      merchant: '',
      products: productsArr,
      secondComponent: showOnlyFavorites,
      list_id: listId
    });
  }
};

export const deleteFromListEvent = (selectedList, allProducts, items, location) => {
  if (items && items.length > 0) {
    const listName = getListName(selectedList.listType);
    const listId = selectedList?.id;

    const newProductArr = [];
    items.forEach(item => {
      const productIndex = allProducts.indexOf(allProducts.find(e1 => e1.sku === item.sku)) + 1;
      const product = {};
      product.position = productIndex;
      product.listingId = item.listingId;
      product.productId = item.productId;
      product.name = item.name;
      product.price = convertPriceToInt(item.finalPriceOnSale);
      product.categoryId = item.mainCategoryId;
      product.sku = item.sku;
      product.variantProperties = item.variantProperty;
      product.brand = item.brand;
      product.catalogName = item.catalogName;

      newProductArr.push(product);
    });

    HBusService.sendEventRequest('DeleteFromMylist', {
      products: newProductArr,
      location,
      action: 'deleted',
      listName,
      listId
    });
  }
};

export const goToProductDetailEvent = (selectedList, allProducts, item) => {
  const pageValue = getListName(selectedList.listType);

  const product = {
    position: allProducts.indexOf(allProducts.find(e1 => e1.sku === item.sku)) + 1,
    listing_id: item.listingId,
    price: item.finalPriceOnSale && convertPriceToInt(item.finalPriceOnSale),
    product_id: item.productId,
    product_status: item.isPreOrder ? 'PreOrder' : 'Instock',
    shippingType: '',
    sku: item.sku,
    tag_label: ''
  };

  HBusService.sendEventRequest('ProductClick', {
    bucket_id: '',
    canonical_url: window.location.href,
    category_hierarchy: [],
    category_status: '',
    experiments: [],
    page_type: 'mylist',
    page_value: pageValue,
    product,
    wasabi_experiment: ''
  });
};

export const linkClickEvent = (location, placement) => {
  HBusService.sendEventRequest('LinkClick', {
    location,
    position: '',
    page_type: 'mylist',
    page_value: '',
    placement,
    text: '',
    url: ''
  });
};

export const productCarouselViewEvent = (
  placementTitle,
  listId,
  items,
  eventType = null,
  clientType = null,
  allProducts = [],
  experiment = null
) => {
  const products = [];
  if (items && items.length > 0) {
    items.forEach(item => {
      const product = {};
      product.listing_id = item.listingId;
      product.position = allProducts.findIndex(product => item.sku === product.sku) + 1;
      product.price = item.finalPriceOnSale && convertPriceToInt(item.finalPriceOnSale);
      product.product_id = item.productId;
      product.product_status = generateProductStatus(item);
      product.sku = item.sku ? item.sku : null;
      product.label = prepareProductLabelsForEvent(item?.labels);
      product.customerReviewCount = item?.customerReviewCount;
      product.customerReviewScore = item?.customerReviewScore;

      products.push(product);
    });
  }

  let properties = null;

  if (clientType) {
    properties = {
      page_type: 'mylist',
      page_value: '',
      placement_id: '',
      placement_title: placementTitle,
      list_id: listId,
      event_type: eventType,
      products,
      clientType,
      experiment
    };
  } else {
    properties = {
      page_type: 'mylist',
      page_value: '',
      placement_id: '',
      placement_title: placementTitle,
      list_id: listId,
      event_type: eventType,
      products,
      experiment
    };
  }

  if (typeof eventType === 'string') {
    HBusService.sendEventRequest('ProductCarouselView', properties);
  }
};

export const productCarouselArrowClickEvent = (selectedList, direction) => {
  const placementTitle = getListName(selectedList.listType);
  HBusService.sendEventRequest('ProductCarouselArrowClick', {
    direction,
    page_type: 'mylist',
    page_value: '',
    placement_id: '',
    placement_title: placementTitle
  });
};

export const myListProductsClickEvent = (
  list,
  items,
  eventType = null,
  clientTypes = null,
  allProducts = [],
  experiment
) => {
  return new Promise((resolve, reject) => {
    const placementTitle = getListName(list?.listType);
    const products = [];
    if (items && items.length > 0) {
      items.forEach(item => {
        const product = {};
        product.listing_id = item.listingId;
        product.position = allProducts.findIndex(product => item.sku === product.sku) + 1;
        product.price = item.finalPriceOnSale && convertPriceToInt(item.finalPriceOnSale);
        product.product_id = item.productId;
        product.product_status = generateProductStatus(item);
        product.sku = item.sku ? item.sku : null;
        product.label = prepareProductLabelsForEvent(item?.labels);
        product.customerReviewCount = item?.customerReviewCount;
        product.customerReviewScore = item?.customerReviewScore;

        products.push(product);
      });
    }

    let properties = null;

    if (clientTypes) {
      properties = {
        page_type: 'mylist',
        page_value: '',
        placement_id: '',
        placement_title: placementTitle,
        list_id: list?.listId,
        event_type: eventType,
        products,
        clientTypes,
        experiment
      };
    } else {
      properties = {
        page_type: 'mylist',
        page_value: '',
        placement_id: '',
        placement_title: placementTitle,
        list_id: list?.listId,
        event_type: eventType,
        products,
        experiment
      };
    }

    if (typeof eventType === 'string') {
      HBusService.sendEventRequest('MyListProductsClick', properties);
      resolve();
      return;
    }

    reject();
  });
};

export const onBookmarkClickEvent = (sku, isExist = false) => {
  const data = {
    location: 'new_checkout',
    position: '',
    page_type: 'mylist',
    page_value: sku,
    placement: 'flag',
    text: isExist ? 'Eklendi' : 'Ekle',
    url: ''
  };

  HBusService.sendEventRequest('LinkClick', data);
};

export const kvkkApproveLinkClickEvent = sku => {
  HBusService.sendEventRequest('LinkClick', {
    location: 'ShareDataPermissionModal',
    page_type: 'productdetail',
    page_value: sku,
    placement: 'button',
    position: 1,
    text: 'İndirim alarmı oluştur',
    url: ''
  });
};

export const showListLinkClickEvent = products => {
  const sku = Array.isArray(products) ? '' : products?.sku || '';
  HBusService.sendEventRequest('LinkClick', {
    location: 'new_checkout',
    position: '',
    page_type: 'mylist',
    page_value: sku,
    placement: 'toast',
    text: 'Görüntüle',
    url: ''
  });
};
