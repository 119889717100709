import { hbusEnabled } from '../appConfig';
import { isBrowser } from 'Utils/helper';

if (isBrowser() && hbusEnabled && !window?.hepsiBus) {
  const script = document.createElement('script');
  script.src = 'https://images.hepsiburada.net/hbus/hbus.min.v3.8.57.js';
  document.body.appendChild(script);
}

let queuedRequestContexts = [];

function queueRequest(eventName, eventData) {
  queuedRequestContexts.push({
    eventName,
    eventData
  });
}

function triggerQueuedEvents() {
  if (queuedRequestContexts) {
    queuedRequestContexts.forEach(({ eventName, eventData }) => {
      trackEvent(eventName, eventData);
    });
  }
}

function trackEvent(eventName, eventData) {
  window.hepsiBus.track(eventName, eventData);
}

const HBusService = {
  sendEventRequest(eventName, eventData) {
    return new Promise(resolve => {
      if (window.hepsiBus) {
        trackEvent(eventName, eventData);
      } else {
        queueRequest(eventName, eventData);
      }

      resolve({ data: {} });
    });
  }
};

if (process.env.BROWSER && window?.addEventListener) {
  window.addEventListener('hbus:ready', () => {
    if (window.hepsiBus) {
      triggerQueuedEvents();
    } else {
      queuedRequestContexts = null;
    }
  });
}

export default HBusService;
