/** Dependencies */
import axios from 'axios';
import Cookies from 'js-cookie';
import createCacheManagerInstance from 'Utils/cacheUtils';
import { loginUrl } from '../appConfig';

const BaseService = () => {
  const cacheService = createCacheManagerInstance();

  const validateStatus = status => {
    return status >= 200 && status < 500 && status !== 401 && status !== 409 && status !== 404;
  };

  const successInterceptor = response => response;

  const failureInterceptor = error => {
    if (error?.response?.status === 401) {
      window.location.href = loginUrl + encodeURIComponent(window.location.href);
      return false;
    }
    return Promise.reject(error);
  };

  const createAxios = (method, url, paramsArgument, configArgument, customOptions) => {
    let config;
    let params;
    let promise;

    const defaultHeaders = {
      Authorization: `Bearer ${customOptions?.token ? customOptions.token : Cookies.get('jwt')}`
    };
    const instance = axios.create({
      headers: defaultHeaders,
      validateStatus
    });

    instance.interceptors.response.use(successInterceptor, failureInterceptor);

    if (configArgument) {
      config = configArgument;
      params = paramsArgument;

      promise = instance[method](url, params, config);
    } else {
      config = paramsArgument;
      promise = instance[method](url, config);
    }

    return promise;
  };

  const makeRequest = (
    method,
    url,
    paramsArgument,
    configArgument,
    cacheSettings,
    customOptions
  ) => {
    let promise;
    let cacheResponse;
    if (cacheSettings && cacheSettings.cacheStatus && cacheSettings.cacheKey) {
      cacheResponse = cacheService.get(cacheSettings);

      if (cacheResponse && !cacheResponse.isExpired) {
        // console.error('Came from cache', cacheSettings.cacheKey);
        promise = Promise.resolve(cacheResponse.cacheValue);
      } else {
        // console.error('Not exist cache, request sent', cacheSettings.cacheKey);
        promise = createAxios(method, url, paramsArgument, configArgument, customOptions)
          // eslint-disable-next-line dot-location
          .then(response => {
            cacheService.put(cacheSettings, response);
            return response;
          })
          // eslint-disable-next-line dot-location
          .catch(error => {
            if (cacheResponse) {
              // console.error('Came from cache - IN ERROR BLOCK', cacheSettings.cacheKey);
              return Promise.resolve(cacheResponse.cacheValue);
            }
            throw error;
          });
      }
    } else {
      // console.error('Doesnt have CacheSettings', url);
      promise = createAxios(method, url, paramsArgument, configArgument, customOptions);
    }

    return promise;
  };

  return {
    get: (url, params, config, cacheSettings, customOptions) =>
      makeRequest('get', url, params, config, cacheSettings, customOptions),
    post: (url, params, config, cacheSettings) =>
      makeRequest('post', url, params, config, cacheSettings),
    put: (url, params, config, cacheSettings) =>
      makeRequest('put', url, params, config, cacheSettings),
    patch: (url, params, config, cacheSettings) =>
      makeRequest('patch', url, params, config, cacheSettings),
    delete: (url, config, cacheSettings) => makeRequest('delete', url, config, null, cacheSettings),
    validateStatus: status => validateStatus(status),
    successInterceptor: response => successInterceptor(response),
    failureInterceptor: error => failureInterceptor(error),
    createAxios: (method, url, paramsArgument, configArgument) =>
      createAxios(method, url, paramsArgument, configArgument)
  };
};

export default BaseService();
