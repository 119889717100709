import HBusService from 'Service/HBusService';
import { getServiceType, getSiteType } from 'Utils/helper';

export const verifyTCKNViewEvent = () => {
  HBusService.sendEventRequest('YouthSegment', {
    event_type: 'View',
    page_type: 'Verification',
    page_value: 'TakeInfo',
    service: getServiceType(),
    site_type: getSiteType()
  });
};

export const verifyTCKNClickEvent = () => {
  HBusService.sendEventRequest('YouthSegment', {
    event_type: 'Click',
    page_type: 'Verification',
    page_value: 'TakeInfo',
    service: getServiceType(),
    site_type: getSiteType()
  });
};

export default {
  verifyTCKNViewEvent,
  verifyTCKNClickEvent
};
