import HBusService from 'Service/HBusService';
import { getSiteType } from 'Utils/cookieUtils';

export const notificationPermissionViewEvent = ({ type = '' }) => {
  HBusService.sendEventRequest('CommunicationPermission', {
    eventType: 'view',
    page_type: 'success',
    page_value: `${type}SuccessPermission`,
    platform: getSiteType()
  });
};

export const notificationPermissionApproveEvent = ({ type = '' }) => {
  HBusService.sendEventRequest('LinkClick', {
    text: 'Onayla',
    action_type: 'ButtonClick',
    page_value: `${type}SuccessPermission`,
    site_type: getSiteType()
  });
};

export const notificationPermissionCanceledEvent = ({ type = '' }) => {
  HBusService.sendEventRequest('LinkClick', {
    text: 'Vazgeç',
    action_type: 'ButtonClick',
    page_value: `${type}SuccessPermission`,
    site_type: getSiteType()
  });
};

export const notificationPermissionClosedEvent = ({ type = '' }) => {
  HBusService.sendEventRequest('LinkClick', {
    text: 'closed',
    action_type: 'ButtonClick',
    page_value: `${type}SuccessPermission`,
    site_type: getSiteType()
  });
};
