import HBusService from 'Service/HBusService';

export const tradeInCloseEvent = () => {
  HBusService.sendEventRequest('DrawerClick', {
    DrawerStatus: 'Closed',
    Placement: 'InstantGratification'
  });
};

export const tradeInViewEvent = () => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: 'DeviceInfo',
    step_number: '1'
  });
};

export const moveOnDeviceSelection = ({ buyingCategoryId, brandName, modelName }) => {
  HBusService.sendEventRequest('DeviceSelection', {
    page_type: 'pdp',
    placement: 'tradein',
    text: 'devam et',
    bCategory: [buyingCategoryId],
    deviceInfo: [{ brand: brandName, model: modelName }]
  });
};

export const preOfferViewEvent = () => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: 'PreOffer',
    step_number: '2'
  });
};

export const moveOnPreOfferEvent = ({
  buyingCategoryId,
  proposedAmount,
  additionalPaymentAmount
}) => {
  HBusService.sendEventRequest('PreOfferDisplay', {
    page_type: 'pdp',
    placement: 'tradein',
    bCategory: [buyingCategoryId],
    proposedAmount,
    additionalpaymentAmount: additionalPaymentAmount,
    text: 'devam et'
  });
};

export const DeviceStatusViewEvent = () => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: 'DeviceSituation',
    step_number: '3'
  });
};

export const moveOnDeviceStatusEvent = ({ buyingCategoryId, questions }) => {
  HBusService.sendEventRequest('DeviceQuestion', {
    page_type: 'pdp',
    placement: 'tradein',
    bCategory: [buyingCategoryId],
    devicecondition: questions?.map(question => ({
      questionId: question.questionId,
      answer: question.selectedAnswerId
    })),
    text: 'devam et'
  });
};

export const ImeiCheckViewEvent = () => {
  HBusService.sendEventRequest('TradeinDrawer', {
    page_type: 'Tradein',
    page_value: 'DeviceImei',
    step_number: '4'
  });
};

export const moveOnImeiCheckEvent = ({
  buyingCategoryId,
  brandName,
  modelName,
  netGratification,
  instant,
  campaign
}) => {
  HBusService.sendEventRequest('ImeiFilled', {
    page_type: 'pdp',
    placement: 'tradein',
    bCategory: [buyingCategoryId],
    deviceInfo: [{ brand: brandName, model: modelName }],
    proposalSummary: [
      {
        totalpayment: netGratification,
        preOffer: instant?.amount?.value,
        additionalPayment: campaign?.amount?.value
      }
    ],
    text: 'Ürünü sepete ekle ve devam et'
  });
};

export default {
  tradeInCloseEvent,
  tradeInViewEvent,
  moveOnDeviceSelection,
  preOfferViewEvent,
  moveOnPreOfferEvent,
  DeviceStatusViewEvent,
  moveOnDeviceStatusEvent,
  ImeiCheckViewEvent,
  moveOnImeiCheckEvent
};
