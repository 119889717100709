import CacheService from 'Service/CacheService';

let cacheManagerInstance = null;

function createCacheManagerInstance() {
  if (!cacheManagerInstance) {
    cacheManagerInstance = new CacheService();
  }
  return cacheManagerInstance;
}

export default createCacheManagerInstance;
