export const CustomListTypeEnums = {
  FAVORITES: 0,
  SAVE_FOR_LATER: 1,
  CUSTOM_LIST: 2,
  ALARM_LIST: 3,
  GIFT_LIST: 5
};

export const CustomListNameEnums = {
  FAVORITES: 'Favorites',
  SAVE_FOR_LATER: 'SaveForLater',
  CUSTOM_LIST: 'CustomList',
  ALARM_LIST: 'PriceDropAlarm',
  GIFT_LIST: 'GiftList'
};

export const httpStatusCodeEnums = {
  OK: 200,
  CREATED: 201,
  PARTIAL_CONTENT: 206,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};

export const MyListResponseCodeEnums = {
  CREATE_ALARM_SUCCESS: 1101,
  CREATE_ALARM_WITHOUT_PERMISSION_SUCCESS: 1102,
  MAX_ITEM: 1201,
  IS_LAZY_1: 1202,
  IS_LAZY_3: 1203,
  KVKK_NEEDED: 1204,
  PERMISSION_NEEDED: 1205,
  DELETE_ALARM_SUCCESS: 1101
};

export const ValidateOtpResponseCodeEnums = {
  INVALID_OTP_CODE: 6000,
  MAX_OTP_VALIDATION_ATTEMPT: 6001,
  OTP_DURATION_EXPIRED: 6002,
  INVALID_OTP_REQUEST: 6003
};

export const CreateOtpResponseCodeEnums = {
  MAX_OTP_REQUEST_EXCEEDED_CODE: 4000,
  GSM_NUMBER_DOES_NOT_EXIST_CODE: 4001,
  CUSTOMER_NOT_FOUND_CODE: 4002
};

export const MenuTypeEnums = {
  NAVIGATION: 'NAVIGATION',
  MY_LIST: 'MY_LIST',
  STATIC: 'STATIC'
};

export const PageTypeEnums = {
  SIDEBAR: 'SIDEBAR',
  MY_ACCOUNT: 'MY_ACCOUNT'
};

export const MenuEnums = {
  ACCOUNT_INFORMATION: 'ACCOUNT_INFORMATION',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  ORDERS: 'ORDERS',
  IBAN_INFORMATION: 'IBAN_INFORMATION',
  COMMENTS: 'COMMENTS',
  ADDRESSES: 'ADDRESSES',
  GIFT_CHECKS: 'GIFT_CHECKS',
  FAVORITES: 'FAVORITES',
  ALL_LISTS: 'ALL_LISTS',
  SOLUTION_CENTER: 'SOLUTION_CENTER',
  LOGOUT: 'LOGOUT',
  WALLET: 'WALLET',
  SETTINGS: 'SETTINGS',
  SUPPORT_REQUESTS: 'SUPPORT_REQUESTS',
  NOTIFICATION_PREFERENCES: 'NOTIFICATION_PREFERENCES',
  REGISTERED_CARDS: 'REGISTERED_CARDS',
  INFLUENCER: 'INFLUENCER',
  SPECIAL_FOR_YOU: 'SPECIAL_FOR_YOU',
  WIN_AS_YOU_PLAY: 'WIN_AS_YOU_PLAY',
  PREMIUM: 'PREMIUM'
};

export const MenuNameEnums = {
  ACCOUNT_INFORMATION: 'AccountInfo',
  CHANGE_PASSWORD: 'PasswordChange',
  ORDERS: 'Orders',
  IBAN_INFORMATION: 'IBANInfo',
  COMMENTS: 'Comments',
  ADDRESSES: 'Address',
  GIFT_CHECKS: 'GiftCert',
  FAVORITES: 'Favorites',
  ALL_LISTS: 'MyList',
  SOLUTION_CENTER: 'SolutionCenter',
  LOGOUT: 'SignOut',
  WALLET: 'Wallet',
  SUPPORT_REQUESTS: 'SupportRequests',
  SETTINGS: 'MyAccount',
  POPULAR_LIST: 'PopularList',
  NOTIFICATION_PREFERENCES: 'NotificationPreferences',
  REGISTERED_CARDS: 'RegisteredCards',
  INFLUENCER: 'Influencer',
  SPECIAL_FOR_YOU: 'SpecialForYou',
  WIN_AS_YOU_PLAY: 'WinAsYouPlay',
  PREMIUM: 'Premium'
};

export const KeyCodeEnums = {
  ENTER: 13
};

export const ProductBoxTypeEnums = {
  MY_LIST: 'MY_LIST',
  CHECKOUT: 'CHECKOUT'
};

export const PopupTypeEnums = {
  SF: 'SF',
  CHECKOUT: 'CHECKOUT'
};

export const NotFoundPageTypeEnums = {
  MY_LIST: 'MY_LIST'
};

export const HttpStatusEnums = {
  OK: 200,
  CREATED: 201,
  PARTIAL_CONTENT: 206,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};

export const VoltranFeatureToggleEnums = {
  IS_VISIBLE_MY_LIST_FAVORITES: 'IsVisibleMyListFavorites',
  IS_VISIBLE_MY_LIST_ADD_TO_LIST: 'IsVisibleMyListAddToList',
  IS_VISIBLE_MY_LIST_ALARM: 'IsVisibleMyListAlarm',
  IS_VISIBLE_MY_LIST_DETAIL: 'IsVisibleMyListDetail',
  IS_VISIBLE_CO_AB_TESTING: 'IsVisibleCOABTesting',
  IS_VISIBLE_ADDRESS_AUTO_SUGGESTION: 'IsVisibleAddressAutoSuggestion',
  IS_VISIBLE_ADD_TO_MY_LISTS: 'IsVisibleAddToMyLists',
  NOTIFICATIN_PERMISSION_PAGE_TYPE_LIST: 'NPPageTypeList',
  IS_ADDRESS_TYPE_SELECTION_ENABLED: 'IsAddressTypeSelectionEnabled',
  ADDRESS_AVAILABLE_DAY_LIST: 'AddressAvailableDayList'
};

export const CacheKeyEnums = {
  VOLTRAN: 'cacheKeyVoltran',
  PREMIUM: 'cacheKeyPremium',
  TRADE_IN: 'cacheKeyTradeIn'
};

export const StaticFileEnums = {
  MEMBERSHIP_AGREEMENT: 'MEMBERSHIP_AGREEMENT',
  ELUCIDATION_TEXT: 'ELUCIDATION_TEXT',
  CONSENT_TEXT: 'CONSENT_TEXT',
  CONFIDENTIALITY_AGREEMENT: 'CONFIDENTIALITY_AGREEMENT'
};

export const SignUpFormPageEnums = {
  CHECKOUT: 'CHECKOUT'
};

export const LazyToCustomerCodeEnums = {
  PASSWORD_IS_NOT_STRONG: 16000,
  MAIL_ADDRESS_IS_NOT_VALID: 16001,
  CUSTOMER_NOT_FOUND: 16002,
  CUSTOMER_IS_NOT_ORDERED_LAZY: 16003,
  MAIL_ADDRESS_IS_EXIST: 16004,
  EMAIL_VARIATION_ALREADY_REGISTERED: 16005,
  NEW_PASSWORD_SHOULD_BE_DIFFERENT_THAN_OLD_PASSWORD: 16006,
  LAZY_PASSWORD_UNABLE_TO_SAVE: 16007,
  TEMP_MAIL_TRICK: 16008
};

export const AddressTypesEnums = {
  PERSONAL: 1,
  CORPORATE: 2
};

export const COABTestingEnums = {
  AS_IS: 1,
  UP: 2,
  DOWN: 3
};

export const AddressFormTypeEnums = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  EDIT_INVALID: 'EDIT_INVALID'
};

export const LoyaltyTargetEnums = {
  ANIMATION_DATA: 'animationdata',
  BACKGROUND_COLOR: 'BackgroundColor',
  CONSTANTS: 'constants',
  LOYALTY_LOGO: 'LoyaltyLogo',
  MOBILE_SIDEBAR: 'mobile_sidebar',
  NON_PREMIUM_TEXT: 'NonPremiumText',
  NON_PREMIUM_URL: 'NonPremiumUrl',
  PREMIUM_TEXT: 'PremiumText',
  PREMIUM_URL: 'PremiumUrl',
  PROFILE_BACKGROUND_COLOR: 'ProfileBackgroundColor',
  WEB_SIDEBAR: 'web_sidebar'
};

export const ProductCartTypeEnums = {
  cozy: 'cozy',
  comfort: 'comfort'
};

export const ProductStatusTypeEnums = {
  PRE_ORDER: 'Preorder',
  OUT_OF_STOCK: 'OutOfStock',
  IN_STOCK: 'InStock'
};

export const RecommendationLabelNames = ['badges', 'campaignLabels', 'tagLabels'];

export const ThirdPartyEnums = {
  VODAFONE: 'vodafone'
};

export default {
  CustomListTypeEnums,
  httpStatusCodeEnums,
  MenuTypeEnums,
  ProductBoxTypeEnums,
  MenuEnums,
  NotFoundPageTypeEnums,
  HttpStatusEnums,
  CustomListNameEnums,
  VoltranFeatureToggleEnums,
  CacheKeyEnums,
  StaticFileEnums,
  MyListResponseCodeEnums,
  LazyToCustomerCodeEnums,
  AddressTypesEnums,
  COABTestingEnums,
  AddressFormTypeEnums,
  ProductCartTypeEnums,
  ProductStatusTypeEnums,
  RecommendationLabelNames,
  ThirdPartyEnums
};
