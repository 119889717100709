/** Dependencies */
import Cookies from 'js-cookie';

class CookieService {
  static getItem = key => {
    return Cookies.get(key);
  };

  static setItem = (key, value, domain = '.hepsiburada.com') => {
    return Cookies.set(key, value, { domain });
  };

  static removeItem = key => {
    return Cookies.remove(key, { domain: '.hepsiburada.com' });
  };
}

export default CookieService;
